/* productosadmin.css */

/* Estilos generales para los contenedores de Categorías y Marcas */
.categorias-collapse, .marcas-collapse {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Estilos para los resúmenes de Categorías y Marcas */
.categorias-collapse summary, .marcas-collapse summary {
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #007b5e;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Estilos al hacer hover sobre el resumen */
.categorias-collapse summary:hover, .marcas-collapse summary:hover {
  background-color: #00563d;
}

/* Animación de apertura de detalles */
.categorias-collapse[open] summary, .marcas-collapse[open] summary {
  background-color: #00563d;
}

/* Contenedor de Categorías y Marcas */
.categorias-collapse .categorias-admin-container, .marcas-collapse .marcas-admin-container {
  padding: 20px;
  border-top: 1px solid #ddd;
}

/* Título de las secciones Categorías y Marcas */
.categorias-admin-container h3, .marcas-admin-container h3 {
  color: #007b5e;
  margin-bottom: 20px;
}

/* Estilos para las tablas de Categorías y Marcas */
.productos-admin-tabla-productos, .productos-admin-tabla-marcas {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Estilos para las celdas de las tablas */
.productos-admin-tabla-productos th, .productos-admin-tabla-productos td,
.productos-admin-tabla-marcas th, .productos-admin-tabla-marcas td {
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
}

/* Estilo para los encabezados de las tablas */
.productos-admin-tabla-productos th, .productos-admin-tabla-marcas th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

/* Filas alternas en las tablas */
.productos-admin-tabla-productos tr:nth-child(even), .productos-admin-tabla-marcas tr:nth-child(even) {
  background-color: #fafafa;
}

/* Estilos para las imágenes en las tablas */
.imagen-tabla {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

/* Estilo para los botones de las tablas */
.productos-admin-tabla-productos button, .productos-admin-tabla-marcas button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

/* Estilos para el botón de Editar */
.btn-editar {
  background-color: #ffc107; /* Amarillo */
  color: #fff;
}

.btn-editar:hover {
  background-color: #e0a800;
}

/* Estilos para el botón de Eliminar */
.btn-eliminar {
  background-color: #dc3545; /* Rojo */
  color: #fff;
}

.btn-eliminar:hover {
  background-color: #c82333;
}

/* Estilos para el botón de Guardar */
.btn-guardar {
  background-color: #28a745; /* Verde */
  color: #fff;
}

.btn-guardar:hover {
  background-color: #218838;
}

/* Estilos para el botón de Cancelar */
.btn-cancelar {
  background-color: #6c757d; /* Gris */
  color: #fff;
}

.btn-cancelar:hover {
  background-color: #5a6268;
}

/* Descripciones truncadas en las tablas */
.descripcion-cell, .descripcion-larga-cell {
  cursor: pointer;
}

.truncated {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded {
  display: inline-block;
  max-width: none;
  white-space: normal;
}

/* Responsividad para dispositivos móviles */
@media (max-width: 768px) {
  .productos-admin-form-row, .productos-admin-form-row-extra {
    flex-direction: column;
  }

  .productos-admin-form-group {
    width: 100%;
  }

  .productos-admin-tabla-productos img.imagen-tabla, .productos-admin-tabla-marcas img.imagen-tabla {
    width: 40px;
    height: 40px;
  }

  .productos-admin-tabla-productos button, .productos-admin-tabla-marcas button {
    padding: 4px 8px;
    font-size: 12px;
  }

  .categorias-admin-container h3, .marcas-admin-container h3 {
    font-size: 16px;
  }
}

/* Estilos para el formulario de agregar producto/marca */
.productos-admin-formulario-agregar {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.productos-admin-form-row, .productos-admin-form-row-extra {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

/* Grupos de formulario */
.productos-admin-form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.productos-admin-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.productos-admin-form-group input, .productos-admin-form-group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Estilos para el botón de Agregar Producto/Marca */
.productos-admin-formulario-agregar button {
  padding: 10px 20px;
  background-color: #007b5e;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.productos-admin-formulario-agregar button:hover {
  background-color: #00563d;
}

.productos-admin-formulario-agregar button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Estilos para las listas desplegables de Categorías y Marcas */
.productos-admin-form-group select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

/* Estilo en el hover de las listas desplegables */
.productos-admin-form-group select:hover {
  border-color: #007b5e;
}

/* Animación de apertura de listas desplegables */
.productos-admin-form-group select:focus {
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
}

/* Estilos específicos para las opciones dentro de las listas desplegables */
.productos-admin-form-group select option {
  padding: 10px;
}

/* Efecto de animación en la tabla de productos y marcas */
.productos-admin-tabla-productos, .productos-admin-tabla-marcas {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
