/* src/components/Navbar/Navbar.css */

.custom-navbar {
  background: #f9f9f9;
  padding: 15px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(100, 149, 237, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  border-radius: 0 0 10px 10px;
  background: 
    linear-gradient(135deg, rgba(249, 249, 249, 0.1), rgba(0, 0, 0, 0.05)), 
    #f9f9f9; 
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(100, 149, 237, 0.7);
  }
  to {
    box-shadow: 0 0 20px rgba(100, 149, 237, 1);
  }
}

.navbar-left, .navbar-center, .navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left {
  flex: 1;
  justify-content: flex-start;
}

.navbar-center {
  flex: 2;
  justify-content: center;
}

.navbar-right {
  flex: 1;
  justify-content: flex-end;
}

.navbar-brand {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  text-decoration: none;
  font-family: 'Playfair Display', serif;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  border: 2px solid #2980b9;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand:hover {
  color: #2980b9;
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.navbar-brand::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #2980b9;
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.navbar-brand:hover::after {
  width: 100%;
}

.navbar-nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar-nav .nav-link {
  color: #2c3e50;
  font-size: 1rem;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s, color 0.3s;
  position: relative;
}

.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 3px;
  background-color: #27ae60;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
}

.navbar-nav .nav-link:hover::after,
.navbar-nav .nav-link.active::after {
  width: 100%;
  left: 0;
}

.navbar-nav .nav-link.active {
  color: #27ae60;
  font-weight: bold;
}

.btn-navbar {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-navbar:hover {
  background-color: #3498db;
  transform: scale(1.05);
}

.btn-navbar.btn-primary {
  background-color: #27ae60;
}

.btn-navbar.btn-primary:hover {
  background-color: #2ecc71;
}

/* Ajustes para Escritorio */
@media (min-width: 769px) { /* Desktop */
  .custom-navbar {
    justify-content: space-between;
  }

  .navbar-left {
    justify-content: flex-start;
  }

  .navbar-center {
    justify-content: center;
  }

  .navbar-right {
    justify-content: flex-end;
  }
}

/* Ajustes para Móviles */
@media (max-width: 768px) { /* Mobile */
  .custom-navbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 0 0 10px 10px;
  }

  .navbar-left, .navbar-center, .navbar-right {
    width: auto;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .navbar-left {
    justify-content: flex-start;
  }

  .navbar-center {
    justify-content: flex-end;
  }

  .navbar-right {
    justify-content: center;
  }

  .navbar-nav {
    flex-direction: row;
    gap: 10px;
    width: auto;
    justify-content: center;
  }

  .nav-links {
    gap: 10px;
    width: auto;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
  }

  .btn-navbar {
    flex: none;
    text-align: center;
  }

  .user-container {
    width: auto;
  }

  .dropdown-menu {
    width: auto;
    right: auto;
    left: 0;
  }

  .search-bar-container {
    max-width: 150px;
    margin: 0;
  }

  .search-bar {
    width: 100%;
  }
}
