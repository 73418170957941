/* src/components/Servicios/ServicioForm.css */

.servicio-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 100%; /* Aseguramos que el formulario ocupe el ancho del contenedor */
  box-sizing: border-box;
}

.servicio-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.servicio-form .form-group {
  margin-bottom: 15px;
}

.servicio-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.servicio-form input[type="text"],
.servicio-form input[type="number"],
.servicio-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.servicio-form textarea {
  resize: vertical;
  height: 80px;
}

.servicio-form input[type="text"]:focus,
.servicio-form input[type="number"]:focus,
.servicio-form textarea:focus {
  border-color: #80bdff;
  outline: none;
}

.gastos-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.gastos-input input[type="text"],
.gastos-input input[type="number"] {
  flex: 1;
}

.btn-agregar-gasto {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.btn-agregar-gasto:hover {
  background-color: #0056b3;
}

.gastos-lista .gasto-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.gastos-lista .gasto-item span {
  color: #555;
}

.btn-eliminar-gasto {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-eliminar-gasto:hover {
  background-color: #c82333;
}

.form-total {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: right;
  color: #333;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.btn-submit,
.btn-cancel {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1em;
}

.btn-submit {
  background-color: #28a745;
  color: #fff;
}

.btn-submit:hover {
  background-color: #218838;
}

.btn-cancel {
  background-color: #dc3545;
  color: #fff;
}

.btn-cancel:hover {
  background-color: #c82333;
}

@media (max-width: 600px) {
  .gastos-input {
    flex-direction: column;
  }

  .form-buttons {
    flex-direction: column;
    gap: 10px;
  }
}
