/* src/components/Navbar/NavLinks.css */

/* Contenedor de enlaces de navegación */
.nav-links {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Enlaces de autenticación */
.btn-navbar {
  background-color: #2980b9; /* Azul primario */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 0.95rem;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-navbar:hover {
  background-color: #3498db; /* Azul más claro al pasar el mouse */
  transform: translateY(-2px);
}

.btn-navbar.btn-primary {
  background-color: #27ae60; /* Verde primario */
}

.btn-navbar.btn-primary:hover {
  background-color: #2ecc71; /* Verde más claro al pasar el mouse */
}

/* Menú desplegable del usuario */
.user-container {
  position: relative;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.user-info:hover {
  background-color: #ecf0f1; /* Fondo gris claro al pasar el mouse */
  transform: translateY(-2px);
}

.user-icon {
  font-size: 1.5rem;
  color: #2980b9; /* Azul para el icono */
}

.user-name {
  font-size: 1rem;
  color: #2c3e50; /* Azul oscuro para el nombre */
}

.dropdown-icon {
  transition: transform 0.3s;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

/* Menú desplegable con animación mejorada */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Posiciona el dropdown justo debajo del contenedor */
  right: 0; /* Alinea el dropdown al lado derecho del contenedor */
  background: white;
  border: 1px solid #2980b9;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  
  /* Inicialmente oculto */
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  pointer-events: none;
  
  /* Transiciones para animar la aparición */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  pointer-events: auto;
  position: absolute;
}

/* Opciones del dropdown */
.dropdown-item {
  padding: 10px 20px;
  color: #2c3e50;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-item:hover {
  background-color: #3498db; /* Azul claro al pasar el mouse */
  color: white;
}

/* Responsivo */
@media (max-width: 768px) {
  .nav-links {
    gap: 10px;
    width: 100%;
    justify-content: space-around;
    display: flex;
    flex-wrap: nowrap;
  }

  .btn-navbar {
    flex: 1 1 auto;
    text-align: center;
  }

  .user-container {
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
    right: auto;
    left: 0;  
    position: absolute;
  }

  .dropdown-menu.show {
    position: absolute;
  }
}

/* 
   --------------------------------------------------------------------------------
   ESTILOS PARA EL LOGO CON FORMA CIRCULAR Y EFECTO DE RELIEVE
   --------------------------------------------------------------------------------
*/
.brand-logo {
  width: 120px;                /* Ajusta el tamaño a tu preferencia */
  height: 50px;               /* Mantén ancho y alto iguales para formar un círculo */
  border-radius: 9x;         /* Hace la imagen circular */
  object-fit: cover;          /* Ajusta la imagen para que no se deforme */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra tipo relieve */
  transition: transform 0.3s, box-shadow 0.3s;
}

.brand-logo:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
