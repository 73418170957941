/* EmpleadoForm.css */

.empleado-form {
    margin-bottom: 20px;
  }
  
  .empleado-form .form-group {
    margin-bottom: 15px;
  }
  
  .empleado-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .empleado-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  
  .empleado-form .form-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .empleado-form .btn-guardar {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  .empleado-form .btn-guardar:hover {
    background-color: #218838;
  }
  
  .empleado-form .btn-cancelar {
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .empleado-form .btn-cancelar:hover {
    background-color: #c82333;
  }
  