/* SidebarDerecho.css */

/* Estilos para el sidebar */
.sidebar-derecho {
  position: fixed;
  top: 98px; /* Mantén este margen para el navbar */
  right: 0;
  width: 350px;
  height: calc(100vh - 80px); /* Ajusta la altura según el navbar */
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: transform 0.4s ease;
  transform: translateX(0);
  z-index: 1000;
}

/* Cuando el sidebar está oculto */
.sidebar-derecho.oculto {
  transform: translateX(100%);
}

/* Botón para alternar el sidebar */
.btn-toggle {
  position: fixed;
  top: 50%;
  right: 350px; /* Alineado con el borde izquierdo del sidebar */
  transform: translateY(-50%);
  z-index: 1001;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  padding: 0;
  transition: background-color 0.3s ease, right 0.4s ease;
}

.btn-toggle:hover {
  background-color: #0056b3;
}

/* Cuando el sidebar está oculto, ajusta la posición del botón */
.btn-toggle.oculto {
  right: 0;
}

/* Rotación del botón cuando está oculto */
.btn-toggle.oculto::before {
  transform: rotate(-135deg);
}

/* Animación de parpadeo */
@-webkit-keyframes blink { /* Safari y Chrome */
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

/* Aplicar la animación de parpadeo cuando el botón está oculto */
.btn-toggle.oculto {
  /* Añadir la animación de parpadeo */
  -webkit-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

/* Estilos para el contenido del sidebar */
.sidebar-content {
  padding: 20px;
}

/* Botones para crear elementos */
.crear-elemento {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.crear-elemento:hover {
  background-color: #218838;
}

/* Estilos para el formulario */
.formulario {
  margin-bottom: 20px;
}

.formulario .form-group {
  margin-bottom: 15px;
}

.formulario label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formulario input,
.formulario textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.formulario .form-buttons {
  display: flex;
  justify-content: flex-end;
}

.formulario .btn-guardar {
  background-color: #17a2b8;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.formulario .btn-guardar:hover {
  background-color: #138496;
}

.formulario .btn-cancelar {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.formulario .btn-cancelar:hover {
  background-color: #c82333;
}

/* Estilos para la lista de elementos */
.lista-elementos,
.lista-elementos li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lista-elementos li {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}

.lista-elementos li:last-child {
  border-bottom: none;
}

.elemento-item {
  padding: 10px;
}

.elemento-info p {
  margin: 5px 0;
}

.elemento-actions {
  margin-top: 10px;
}

.elemento-actions button {
  margin-right: 10px;
}

.btn-editar {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-editar:hover {
  background-color: #0056b3;
}

.btn-eliminar {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-eliminar:hover {
  background-color: #c82333;
}

/* Encabezados */
.sidebar-content h3 {
  margin-top: 20px;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .sidebar-derecho {
    width: 100%;
    height: calc(100vh - 80px); /* Ajusta según el navbar */
  }

  .btn-toggle {
    right: 0;
    transform: translateY(-50%);
  }

  .btn-toggle.oculto {
    right: 0;
  }
}

@media (max-width: 480px) {
  .sidebar-derecho {
    width: 100%;
    height: calc(100vh - 80px); /* Ajusta según el navbar */
  }

  .btn-toggle {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .btn-toggle.oculto {
    width: 35px;
    height: 35px;
  }
}
