/* src/components/ventas/Sidebar.css */

/* Estilos Generales para el Sidebar */
.sidebar-inventario {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #2c3e50;
  border-right: 1px solid #34495e;
  border-radius: 0 8px 8px 0;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 998; /* Menor que el botón */
  transition: width 0.3s ease-in-out;
  overflow: hidden; /* Oculta contenido excedente */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  height: auto; /* Altura adaptativa */
}

/* Estado Abierto del Sidebar */
.sidebar-inventario.open {
  width: 220px;
}

/* Estado Cerrado del Sidebar */
.sidebar-inventario.closed {
  width: 60px;
}

/* Estilos del Contenido Interno del Sidebar */
.sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

/* Lista de Menús */
.sidebar-menu {
  list-style: none;
  padding: 20px 0 0 0;
  margin: 0;
  width: 100%;
}

/* Elementos del Menú */
.sidebar-menu li {
  margin: 10px 0;
  width: 100%;
}

/* Enlaces del Menú */
.sidebar-menu li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s, transform 0.2s;
  border-radius: 5px;
  white-space: nowrap; /* Evita el salto de línea */
}

/* Iconos del Menú */
.sidebar-menu li a i {
  margin-right: 10px;
  font-size: 1.2rem;
  width: 20px; /* Alineación de iconos */
  text-align: center;
}

/* Texto del Menú */
.sidebar-menu li a span {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* Hover en Enlaces del Menú */
.sidebar-menu li a:hover {
  background-color: rgba(0, 181, 93, 0.2);
  transform: translateX(5px);
}

/* Estado Activo de los Enlaces del Menú */
.sidebar-menu li a.active {
  background-color: rgba(0, 181, 93, 0.3);
  color: #1abc9c;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

/* Estado Cerrado: Ocultar Texto del Menú */
.sidebar-inventario.closed .sidebar-menu li a span {
  opacity: 0;
}

/* Transición para Ocultar/Mostrar Texto */
.sidebar-menu li a span {
  transition: opacity 0.3s ease-in-out;
}

/* Estilos del Botón de Toggle */
.sidebar-toggle-btn {
  position: fixed; /* Posición fija en la pantalla */
  top: 50%;
  left: 220px; /* Posición cuando el sidebar está abierto */
  transform: translateY(-50%);
  background-color: #2c3e50;
  border: none;
  color: #ecf0f1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.3s ease-in-out;
  z-index: 999; /* Asegura que esté encima del sidebar */
}

/* Posición del Botón cuando el Sidebar está Cerrado */
.sidebar-toggle-btn.closed {
  left: 60px; /* Posición cuando el sidebar está cerrado */
}

/* Media Queries para Responsividad */

/* Pantallas Medianas (Tablets) */
@media (max-width: 768px) {
  .sidebar-inventario.open {
    width: 220px;
  }

  .sidebar-inventario.closed {
    width: 60px;
  }

  /* Posición del Botón de Toggle */
  .sidebar-toggle-btn.open {
    left: 220px;
  }

  .sidebar-toggle-btn.closed {
    left: 60px;
  }

  /* Ajustes de Margen para el Contenido Principal.sidebar-inventario.open ~ .contenido {
    margin-left: 220px;
  }
 */
  
 
}

/* Pantallas Pequeñas (Móviles) */
@media (max-width: 480px) {
  .sidebar-inventario.open {
    width: 180px;
  }

  .sidebar-inventario.closed {
    width: 1px;
  }

  /* Ajustes de Tamaño del Botón de Toggle */
  .sidebar-toggle-btn {
    width: 25px;
    height: 25px;
  }

  .sidebar-toggle-btn.open {
    left: 180px;
  }

  .sidebar-toggle-btn.closed {
    left: -1px;
  }

  /* Ajustes de los Menús */
  .sidebar-menu li a {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .sidebar-menu li a i {
    font-size: 1rem;
    margin-right: 5px;
    width: 15px;
  }

  .sidebar-menu li a span {
    font-size: 0.9rem;
  }
}
