/* src/components/DropdownWithCheckbox.css */

.dropdown-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.dropdown-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-header:hover {
  background-color: #f9f9f9;
}

.dropdown-arrow {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  transition: transform 0.2s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  z-index: 1000;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item.read-only {
  cursor: default;
}

.dropdown-item.read-only:hover {
  background-color: transparent;
}

.dropdown-item input {
  margin-right: 8px;
}
