/* Estilos para el perfil del usuario */
.user-profile-container {
  max-width: 900px;
  margin: 80px auto; /* Aseguramos que tenga margen superior para no estar pegado al navbar */
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.user-profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.user-profile-header h1 {
  font-size: 2rem;
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 10px;
}

.user-profile-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.user-profile-details p {
  font-size: 1.1rem;
  color: #34495e;
}

.user-profile-details .label {
  font-weight: bold;
  color: #2980b9;
}

.user-profile-footer {
  margin-top: 30px;
  text-align: center;
}

.user-profile-footer button {
  background-color: #2980b9;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-footer button:hover {
  background-color: #3498db;
}
