/* src/components/ventas/GestionarDestacados.css */

.gestionar-destacados-container {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .gestionar-destacados-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .productos-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .productos-table th,
  .productos-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  .productos-table th {
    background-color: #4CAF50;
    color: white;
  }
  
  .productos-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .productos-table tr:hover {
    background-color: #ddd;
  }
  
  .btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .agregar-destacado {
    background-color: #007bff;
  }
  
  .agregar-destacado:hover {
    background-color: #0056b3;
  }
  
  .eliminar-destacado {
    background-color: #dc3545;
  }
  
  .eliminar-destacado:hover {
    background-color: #c82333;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 12px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .error-message button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #721c24;
  }
  