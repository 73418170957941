/* src/components/ReporteServicios/ReporteServicios.css */

.reporte-servicios-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
  }
  
  .reporte-servicios-container h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #2c3e50;
  }
  
  .reporte-filtros-form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    align-items: flex-end;
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }
  
  .form-group label {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .form-group select,
  .form-group input {
    padding: 6px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-generar {
    padding: 8px 16px;
    background-color: #0275d8;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.2s ease;
  }
  
  .btn-generar:hover {
    background-color: #025aa5;
  }
  
  .resultado-reporte {
    margin-top: 1rem;
  }
  
  .reporte-tabla {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .reporte-tabla th,
  .reporte-tabla td {
    border: 1px solid #ccc;
    padding: 8px 12px;
    font-size: 0.9rem;
    text-align: left;
  }
  
  .reporte-tabla th {
    background-color: #e9ecef;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .btn-pdf {
    padding: 8px 16px;
    background-color: #dc3545;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 15px;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.2s ease;
  }
  
  .btn-pdf:hover {
    background-color: #c82333;
  }