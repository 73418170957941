/* ListaProductos.css */

/* Estilos para los elementos de media en modo edición */
.media-item {
    position: relative;
    display: inline-block;
    margin: 5px;
  }
  
  .media-eliminada {
    opacity: 0.5;
  }
  
  .media-delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  
  .media-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
  }
  
  /* Estilos para la sección de enlaces de media */
  .media-links-section {
    margin-top: 10px;
  }
  
  .media-link-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .media-link-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-agregar-media-link {
    padding: 6px 12px;
    background-color: #007b5e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-agregar-media-link:hover {
    background-color: #00563d;
  }
  
  /* Estilos para inputs específicos */
  .input-nombre,
  .input-precio,
  .input-stock,
  .textarea-descripcion,
  .textarea-descripcion-larga,
  .select-marca {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Estilos para el input de subida de media */
  .media-upload-input {
    display: block;
    margin-top: 10px;
  }
  

  /* Responsividad específica para ListaProductos */
  @media (max-width: 768px) {
    .media-item {
      width: 100%;
    }
  
    .media-upload-input {
      width: 100%;
    }
  
    .media-link-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .media-link-input {
      margin-bottom: 5px;
      width: 100%;
    }
  
    .btn-agregar-media-link {
      width: 100%;
    }
  }
  /* ListaProductos.css */

/* ... tus estilos existentes ... */

/* Estilos para la barra de carga */
.upload-progress-container {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .upload-progress-bar {
    height: 20px;
    background-color: #4caf50;
    width: 0%;
    border-radius: 5px;
    text-align: center;
    color: white;
    line-height: 20px;
    transition: width 0.4s ease;
  }
  
  /* Estilos para mensajes de estado */
  .upload-message {
    margin-top: 10px;
    font-weight: bold;
  }
  