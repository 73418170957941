/* EditarPerfil.css */

/* Contenedor Principal del Formulario */
.editar-perfil-container {
  background: #ffffff; /* Fondo blanco para mantener el look limpio */
  padding: 20px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  border-radius: 8px;
  width: 80%; /* Ancho ajustado para el formulario */
  margin: 40px auto;
  transition: all 0.3s ease-in-out;
}

/* Título principal */
.editar-perfil-container h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 30px;
  font-family: 'Playfair Display', serif;
}

/* Estilo de los inputs */
.form-control {
  border: 2px solid #2980b9; /* Borde de color azul para los campos */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 15px;
  font-size: 1rem;
  color: #2c3e50; /* Texto oscuro */
  transition: border-color 0.3s ease-in-out;
}

/* Estilo al pasar el mouse o cuando el campo está activo */
.form-control:focus {
  border-color: #27ae60; /* Verde cuando el campo está en foco */
  box-shadow: 0 0 4px rgba(39, 174, 96, 0.5); /* Sombra verde */
}

/* Estilo de los botones */
button[type='submit'] {
  background-color: #2980b9; /* Azul primario */
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

/* Estilo de hover para los botones */
button[type='submit']:hover {
  background-color: #3498db; /* Azul más claro al pasar el mouse */
  transform: scale(1.05);
}

/* Estilo para los enlaces de "Mostrar/Ocultar" en las contraseñas */
button[variant="link"] {
  background: none;
  color: #2980b9;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
}

button[variant="link"]:hover {
  color: #27ae60;
  text-decoration: underline;
}

/* Estilo para los mensajes de error */
.form-text.text-danger {
  font-size: 0.875rem;
  color: #e74c3c;
}

/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .editar-perfil-container {
    width: 90%;
    padding: 15px;
  }

  .editar-perfil-container h2 {
    font-size: 1.5rem;
  }
}
