/* CSS para el componente Home - Estilos locales no globales */
.home-container {
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
  /* Variables de la paleta de colores para este componente */
  --primary: #0CA39B;
  --primary-dark: #0A7D78;
  --accent: #F2F2F2;
  --text-dark: #2c3e50;
}

/* ============================ */
/* Sección Hero con loop continuo */
/* ============================ */
.home-container .hero-section {
  position: relative;
  overflow: hidden;
  height: 50vh;
}

.home-container .hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* Duplicamos el ancho para colocar dos copias de la imagen */
  height: 100%;
  background: url('/public/heroimagen.jpg') repeat-x;
  background-size: auto 100%;
  animation: homeSlide 30s linear infinite;
}

/* Renombramos la animación para evitar conflictos globales */
@keyframes homeSlide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.home-container .hero-overlay {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.home-container .hero-content {
  max-width: 800px;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.home-container .hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: none;
}

.home-container .hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: none;
}

/* Botones del Hero */
.home-container .hero-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.home-container .btn-hero {
  padding: 14px 30px;
  border: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* Se actualizan los botones para usar la paleta basada en el color principal */
.home-container .btn-servicios,
.home-container .btn-productos {
  background: linear-gradient(45deg, var(--primary), var(--primary-dark));
}

.home-container .btn-hero:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* ============================ */
/* Sección de Servicios */
/* ============================ */
.home-container .services-section {
  padding: 2rem 0;
  background: #f8f9fa;
}

.home-container .section-title {
  text-align: center;
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
}

.home-container .services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 1rem;
  padding: 0 1rem;
}

.home-container .service-card {
  background: white;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  min-height: 120px;
}

.home-container .service-card:hover {
  transform: translateY(-5px);
}

.home-container .service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}

.home-container .service-header h3 {
  color: var(--text-dark);
  font-size: 0.85rem;
  margin: 0;
  line-height: 1.3;
}

.home-container .service-price {
  background: var(--primary-dark);
  color: white;
  white-space: nowrap;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.75rem;
}

.home-container .service-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}

.home-container .service-duration {
  color: #666;
  font-size: 0.75rem;
}

.home-container .service-meta span::after {
  content: "*";
  color: #e74c3c;
  margin-left: 3px;
}

/* Botón de Agendar */
.home-container .btn-agendar {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-top: 0.5rem;
  box-shadow: 0 2px 8px rgba(12, 163, 155, 0.3);
}

.home-container .btn-agendar svg {
  font-size: 0.9rem;
  color: white;
  transition: transform 0.2s ease;
}

.home-container .btn-agendar:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(12, 163, 155, 0.4);
}

.home-container .btn-agendar:hover svg {
  transform: scale(1.05);
}

/* Horario de Atención */
.home-container .horario-atencion {
  text-align: center;
  margin-top: 1.5rem;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.home-container .horario-atencion::before {
  content: "* Los tiempos de duración y precios son aproximados y pueden variar según las características del cabello";
  display: block;
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 1rem;
  text-align: left;
  font-style: italic;
  line-height: 1.2;
}

.home-container .horario-atencion h3 {
  color: var(--text-dark);
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.home-container .horario-atencion p {
  color: #555;
  font-size: 0.95rem;
  margin: 0.3rem 0;
}

/* Botón Mostrar Más/Menos */
.home-container .btn-toggle-services {
  padding: 8px 25px;
  background: linear-gradient(45deg, var(--primary), var(--primary-dark));
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.home-container .btn-toggle-services:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* ============================ */
/* Carrusel de Productos Destacados */
/* ============================ */
.home-container .featured-products {
  padding: 3rem 0;
  background: white;
}

.home-container .product-card {
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  transition: all 0.3s ease;
  background: white;
  cursor: pointer;
}

.home-container .product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.home-container .product-image {
  height: 200px;
  width: 100%;
  object-fit: contain;
  margin: 1rem 0;
}

.home-container .product-price {
  color: var(--text-dark);
  font-weight: bold;
  font-size: 1.2rem;
}

.home-container .product-title {
  color: var(--text-dark);
  font-size: 1rem;
  min-height: 3rem;
  margin-bottom: 0.5rem;
}

.home-container .card-content {
  text-align: center;
  padding: 1rem;
}

/* ============================ */
/* Flechas del carrusel */
/* ============================ */
.home-container .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.home-container .custom-arrow:hover {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.home-container .next-arrow {
  right: -25px;
}

.home-container .prev-arrow {
  left: -25px;
}

/* ============================ */
/* Puntos del carrusel */
/* ============================ */
.home-container .slick-dots {
  bottom: -40px !important;
}

.home-container .slick-dots li button:before {
  font-size: 12px;
  color: var(--primary-dark);
}

.home-container .slick-dots li.slick-active button:before {
  color: var(--primary);
}

/* ============================ */
/* Responsive */
/* ============================ */
@media (max-width: 768px) {
  .home-container .hero-content h1 {
    font-size: 2.5rem;
  }

  .home-container .hero-subtitle {
    font-size: 1.2rem;
  }

  .home-container .next-arrow {
    right: -15px;
  }

  .home-container .prev-arrow {
    left: -15px;
  }

  .home-container .services-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .home-container .hero-section {
    height: 60vh;
  }

  .home-container .hero-content h1 {
    font-size: 2rem;
  }

  .home-container .hero-subtitle {
    font-size: 1rem;
  }

  .home-container .btn-hero {
    width: 100%;
    margin: 5px 0;
  }

  .home-container .custom-arrow {
    padding: 10px;
    font-size: 0.8rem;
  }
}

/* Estados de carga y error */
.home-container .loading,
.home-container .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: var(--text-dark);
}

.home-container .error {
  color: #dc3545;
}
