/* EmpleadoItem.css */

.empleado-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .empleado-item:last-child {
    border-bottom: none;
  }
  
  .empleado-info p {
    margin: 5px 0;
  }
  
  .empleado-actions {
    margin-top: 10px;
  }
  
  .empleado-actions button {
    margin-right: 10px;
  }
  
  .btn-editar {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .btn-editar:hover {
    background-color: #0056b3;
  }
  
  .btn-eliminar {
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .btn-eliminar:hover {
    background-color: #c82333;
  }
  