/* src/components/ventas/InventarioLayout.css */

.inventario-layout {
  display: flex;
  height: 100vh; /* Ocupa toda la altura de la ventana */
}

.contenido {
  flex: 1;
  padding: 80px 20px 20px 20px; /* Ajusta el padding superior para el Navbar */
  transition: margin-left 0.3s ease, padding 0.3s ease;
  overflow-y: auto; /* Scroll vertical para contenido que excede la altura */
}

/* Ajustar el margen cuando el Sidebar está abierto */
.contenido.sidebar-open {
  margin-left: 200px; /* Mismo ancho que el Sidebar */
}

/* Ajustar el margen cuando el Sidebar está cerrado */
.contenido.sidebar-closed {
  margin-left: 0;
}

/* Mejoras para dispositivos móviles */
@media (max-width: 768px) {
  /* Ajusta el layout principal a columna para móviles */
  .inventario-layout {
    flex-direction: column;
  }

  
}

