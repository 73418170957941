/* General */
body {
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .modal-header {
    background-color: #28a745;
    color: white;
    border-bottom: none;
    animation: fadeIn 1s ease;
  }
  
  .modal-title {
    font-size: 1.6rem;
    margin: 0;
  }
  
  .modal-body {
    padding: 2rem;
  }
  
  .form-control {
    border-radius: 0.5rem;
    border: 1px solid #ced4da;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    animation: focusEffect 0.3s ease;
  }
  
  .btn-primary {
    background-color: #28a745;
    border-color: #28a745;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    animation: buttonFadeIn 1s ease;
  }
  
  .btn-primary:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  /* Password Fields */
  .password-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .password-group input {
    padding-right: 2.5rem;
    border-radius: 0.5rem;
  }
  
  .password-group .form-check {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.75rem;
    margin-right: 1rem;
  }
  
  .password-group .form-check input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .password-info {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(0.5rem);
  }
  
  .password-group input:focus + .password-info,
  .password-group input:hover + .password-info {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  
  .password-info ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .password-info li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Success and Error Messages */
  .alert-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
    animation: fadeIn 1s ease;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    animation: fadeIn 1s ease;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes focusEffect {
    from {
      border-color: #ced4da;
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25);
    }
    to {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  }
  
  @keyframes buttonFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Layout Enhancements */

  
