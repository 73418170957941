/* src/components/AgregarMarca/AgregarMarca.css */

/* Formulario para agregar marca */
.agregar-marca-formulario {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ajuste para ocupar todo el ancho disponible */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.agregar-marca-formulario h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
  text-align: center;
}

.agregar-marca-formulario input[type="text"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1em;
  transition: border-color 0.3s;
}

.agregar-marca-formulario input[type="text"]:focus {
  border-color: #007b5e; /* Mismo color que ProductosAdmin */
  outline: none;
}

.agregar-marca-formulario button {
  padding: 8px;
  background-color: #007b5e; /* Mismo color que ProductosAdmin */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.agregar-marca-formulario button:hover:not(:disabled) {
  background-color: #00563d; /* Mismo efecto hover que ProductosAdmin */
}

.agregar-marca-formulario button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
