.custom-footer {
  background: #ffffff; /* Fondo claro */
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(100, 149, 237, 0.3); /* Relieve */
  border-top: 3px solid #2980b9;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}

.footer-section {
  flex: 1 1 auto;
  text-align: left;
  min-width: 200px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #2c3e50;
}

.footer-section p,
.footer-section a {
  font-size: 0.95rem;
  margin: 5px 0;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}

.footer-section a:hover {
  color: #2980b9;
}

.social-links {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 10px;
}

.social-icon {
  font-size: 2.5rem !important; /* Tamaño reducido */
  color: #555; /* Color base */
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.social-icon:hover {
  color: #ffffff; /* Color al pasar por encima */
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(41, 128, 185, 0.8); /* Efecto de brillo */
}

.social-icon.instagram {
  color: #E1306C !important;
}

.social-icon.whatsapp {
  color: #25D366 !important;
}

.footer-minimal {
  margin-top: 20px;
  font-size: 0.85rem;
  text-align: center;
  color: #999;
  width: 100%;
}

/* Ajustes para móviles */
@media (max-width: 768px) {
  .custom-footer {
    padding: 15px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-section {
    width: 100%;
    text-align: center; /* Centra los textos en móviles */
    margin-bottom: 10px;
  }

  .footer-section h4 {
    margin-bottom: 8px;
  }

  .footer-section p,
  .footer-section a {
    justify-content: center; /* Centra los íconos y textos */
  }

  .social-links {
    justify-content: center;
    gap: 15px; /* Reduce el espacio entre íconos en móviles */
  }
}
