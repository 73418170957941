/* src/components/MarcasAdmin/MarcasAdmin.css */

.marcas-admin-container {
    margin-top: 20px;
  }
  
  .marcas-admin-tabla {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .marcas-admin-tabla th,
  .marcas-admin-tabla td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .marcas-admin-tabla th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .marcas-admin-tabla tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .marcas-admin-tabla tr:hover {
    background-color: #ddd;
  }
  
  .marcas-admin-tabla button {
    margin-right: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .marcas-admin-tabla button:hover {
    opacity: 0.8;
  }
  