/* src/components/ModalProducto/ModalProducto.css */

/* Variables CSS para facilitar el mantenimiento y la consistencia */
:root {
  --modal-overlay-bg: rgba(0, 0, 0, 0.5);
  --modal-content-bg: #fff;
  --primary-color: #007b5e;
  --secondary-color: #555;
  --hover-color: #005f3f;
  --close-button-color: #888;
  --close-button-hover-color: #333;
  --scrollbar-width: 8px;
  --scrollbar-thumb-color: var(--primary-color);
  --scrollbar-thumb-radius: 4px;
  /* Agrega más variables según sea necesario */
}

/* Modal Overlay */
.productos-clientes-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Modal Content */
.productos-clientes-modal-content {
  background-color: var(--modal-content-bg);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 1000px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-height: 90vh;
  transition: transform 0.3s ease;
}

/* Modal Body */
.productos-clientes-modal-body {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

/* Columna Izquierda: Miniaturas */
.productos-clientes-modal-left {
  flex: 0 0 80px; /* Ancho fijo para las miniaturas */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

/* Contenedor de Miniaturas */
.productos-clientes-modal-miniaturas {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60px;
}

/* Miniatura Individual */
.miniatura {
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: border 0.3s ease;
}

.miniatura.activa {
  border-color: var(--primary-color);
}

.miniatura img,
.miniatura video {
  width: 100%;
  height: 60px;
  object-fit: contain;
  border-radius: 4px;
}

.miniatura-sin-media img {
  width: 100%;
  height: 60px;
  object-fit: contain;
  border-radius: 4px;
}

/* Columna Centro: Imagen Principal con Botones Alrededor */
.productos-clientes-modal-image-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productos-clientes-modal-nombre-sobre-imagen {
  font-size: 1.75rem;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

/* Imagen del Producto */
.productos-clientes-modal-image {
  width: 100%;
  height: 400px; /* Altura fija para contener la imagen */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Previene que la imagen se desborde */
}

.productos-clientes-modal-image img,
.productos-clientes-modal-image video {
  max-width: 100%; /* Ancho máximo del contenedor */
  max-height: 100%; /* Altura máxima del contenedor */
  width: auto; /* Permite ajuste proporcional del ancho */
  height: auto; /* Permite ajuste proporcional de la altura */
  object-fit: contain; /* Mantiene la imagen completa sin distorsionar */
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: zoom-in;
  pointer-events: none; /* Evita que la imagen reciba clics */
  image-rendering: -webkit-optimize-contrast; /* Para evitar pixelación en algunos navegadores */
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated; /* Para asegurar que no se suavicen las imágenes al aumentar el zoom, si es deseable */
}

/* Control del zoom para asegurar que siempre se ve la imagen completa */
.productos-clientes-modal-image:hover img,
.productos-clientes-modal-image:hover video {
  transform: scale(1.2); /* Ajusta el zoom como prefieras */
  pointer-events: auto; /* Restablece los eventos de puntero al pasar el ratón */
}

/* Botones de Navegación alrededor de la imagen */
.navegacion-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  z-index: 1005; /* Aumentar z-index para asegurar visibilidad */
}

/* Asegura que los botones no se superpongan con la imagen al hacer zoom */
.productos-clientes-modal-image:hover .navegacion-button {
  z-index: 1006; /* Incrementa el z-index para que estén siempre visibles */
}

.navegacion-button.anterior-button {
  left: 0;
}

.navegacion-button.siguiente-button {
  right: 0;
}

.navegacion-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navegacion-button:hover:not(:disabled),
.navegacion-button:focus:not(:disabled) {
  background-color: var(--hover-color);
  outline: none;
}

/* Precio debajo de la imagen */
.productos-clientes-modal-precio-abajo-imagen {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.precio-valor {
  font-size: 1.125rem;
  color: var(--secondary-color);
}

.precio-valor strong {
  color: var(--primary-color);
}

.precio-disclaimer {
  font-size: 0.85em;
  color: #777;
  margin-top: 5px;
}

/* Columna Derecha: Información del Producto */
.productos-clientes-modal-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Descripción del Producto */
.productos-clientes-modal-descripcion {
  font-size: 1.125rem;
  color: var(--secondary-color);
}

.productos-clientes-modal-descripcion p,
.descripcion-larga {
  white-space: pre-line;
}

/* Descripción Larga */
.descripcion-larga {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 4;
}

.descripcion-larga.expandida {
  display: block;
}

/* Botón para mostrar/ocultar la descripción larga */
.productos-clientes-modal-toggle {
  margin-top: 10px;
  color: var(--primary-color);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.productos-clientes-modal-toggle:hover,
.productos-clientes-modal-toggle:focus {
  color: var(--hover-color);
  outline: none;
}

/* Close Button - Unificado para el modal principal y el modal de imagen */
.productos-clientes-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  line-height: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1002;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.productos-clientes-modal-close:hover,
.productos-clientes-modal-close:focus {
  background-color: #f0f0f0;
  color: var(--close-button-hover-color);
  outline: none;
}

/* Disclaimer */
.productos-clientes-modal-disclaimer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9; /* Color de fondo suave */
  border-left: 4px solid var(--primary-color); /* Línea lateral para resaltar */
}

.productos-clientes-modal-disclaimer p {
  font-size: 0.9rem;
  color: #777;
  margin: 0;
}

/* Imagen Modal Overlay */
.productos-clientes-imagen-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 20px;
  overflow: hidden;
}

/* Contenedor de la Imagen */
.productos-clientes-imagen-modal-content {
  position: relative;
  max-width: 90vw; /* Máximo ancho */
  max-height: 90vh; /* Máximo alto */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Imagen en el Modal */
.productos-clientes-imagen-modal-content img {
  width: auto; /* Ancho automático */
  height: auto; /* Alto automático */
  max-width: 100%; /* Limita la imagen al contenedor */
  max-height: 100%; /* Limita la imagen al contenedor */
  object-fit: contain; /* La imagen se ajusta completamente sin recortar */
  border-radius: 8px;
  cursor: zoom-out;
  image-rendering: auto; /* Evita pixelación */
}

.productos-clientes-video-modal-zoom {
  max-width: 90vw;
  max-height: 95vh;
  object-fit: contain;
  border-radius: 8px;
  object-position: center;
  cursor: zoom-out;
}

/* Scrollbar Personalizada para navegadores basados en WebKit */
.productos-clientes-modal-miniaturas::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.productos-clientes-modal-miniaturas::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: var(--scrollbar-thumb-radius);
}

/* Miniaturas Activas */
.miniatura.activa {
  border-color: var(--primary-color);
}

/* Miniaturas */
.miniatura img,
.miniatura video {
  border-radius: 4px;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
  .productos-clientes-modal-body {
    flex-direction: column;
  }

  .productos-clientes-modal-left,
  .productos-clientes-modal-image-container,
  .productos-clientes-modal-right {
    width: 100%;
  }

  .productos-clientes-modal-nombre-sobre-imagen {
    font-size: 1.5rem;
  }

  .productos-clientes-modal-image img {
    max-height: 300px;
  }

  .productos-clientes-modal-precio-abajo-imagen {
    margin-top: 15px;
  }

  .productos-clientes-modal-descripcion {
    text-align: left;
  }

  /* Ajustes para el Disclaimer en móviles */
  .productos-clientes-modal-disclaimer {
    margin-top: 15px;
    padding: 8px;
  }

  .productos-clientes-modal-miniaturas {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

  .miniatura {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .productos-clientes-modal-content {
    padding: 15px;
  }

  .productos-clientes-modal-nombre-sobre-imagen {
    font-size: 1.2rem;
  }

  .productos-clientes-modal-precio-abajo-imagen {
    font-size: 0.9rem;
    margin: 10px 0;
  }

  .productos-clientes-modal-descripcion {
    font-size: 0.9rem;
  }

  .productos-clientes-modal-image img {
    max-height: 250px;
  }

  /* Ajustes adicionales para el Disclaimer en pantallas muy pequeñas */
  .productos-clientes-modal-disclaimer {
    margin-top: 10px;
    padding: 5px;
  }

  .miniatura {
    width: 40px;
    height: 40px;
  }
}

/* Opcional: Transiciones Suaves */
.productos-clientes-modal-body,
.productos-clientes-modal-left,
.productos-clientes-modal-image-container,
.productos-clientes-modal-right,
.productos-clientes-modal-image,
.productos-clientes-modal-precio-abajo-imagen,
.productos-clientes-modal-descripcion,
.productos-clientes-modal-disclaimer,
.productos-clientes-modal-miniaturas,
.miniatura,
.navegacion-button {
  transition: all 0.3s ease;
}

/* Estilos para los Botones de WhatsApp */
.productos-clientes-modal-botones-whatsapp {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.boton-compartir,
.boton-consultar-precio {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #25D366; /* Color de WhatsApp */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.boton-compartir:hover,
.boton-consultar-precio:hover {
  background-color: #1da851; /* Color al pasar el ratón */
}

.boton-compartir svg,
.boton-consultar-precio svg {
  margin-right: 8px;
  font-size: 18px;
}

/* Ajustes Responsivos para los Botones */
@media (max-width: 768px) {
  .boton-compartir,
  .boton-consultar-precio {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .boton-compartir,
  .boton-consultar-precio {
    font-size: 12px;
    padding: 8px 12px;
  }

  .boton-compartir svg,
  .boton-consultar-precio svg {
    font-size: 16px;
  }
}
