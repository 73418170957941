/* src/components/Navbar/SearchBar.css */

.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 301px;
  margin: 0 auto;
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.search-input {
  flex-grow: 1;
  padding: 8px 7px;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
}

.clear-button {
  background: none;
  border: none;
  padding: 0 10px;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.clear-button:hover {
  color: #333;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 10;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.no-results {
  text-align: center;
  color: #999;
}

.suggestion-type {
  color: #888;
  font-style: italic;
}

/* Ajustes específicos para dispositivos móviles */
@media (max-width: 768px) { /* Mobile */
  .search-bar-container {
    max-width: 150px;
    margin: 0;
  }

  .search-bar {
    width: 100%;
  }
}
