/* Estructura general para que el contenido empuje el footer */
html, body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Asegura que el contenido ocupe la altura completa */
  }
  
  .container {
    flex: 1; /* Permite que el contenido crezca y ocupe el espacio disponible */
    padding: 20px; /* Ajusta según tus necesidades */
  }
  