/* src/components/DashboardContent/DashboardContent.css */

.dashboard-content-inner {
  padding: 20px;
}

.gestion-puestos {
  margin-bottom: 40px;
}

.puesto-card {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}

.puesto-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.puesto-details {
  flex: 1;
}

.puesto-estadisticas {
  flex: 1;
  margin-left: 20px;
}

.puesto-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-editar {
  background-color: #2196f3;
  color: white;
}

.btn-eliminar {
  background-color: #f44336;
  color: white;
}

.agregar-puesto-container {
  margin-top: 40px;
}

.agregar-puesto-form label {
  display: block;
  margin-bottom: 5px;
}

.agregar-puesto-form input,
.agregar-puesto-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.btn-agregar {
  background-color: #4caf50;
  color: white;
  width: 100%;
}

.error-text {
  color: red;
  margin-bottom: 15px;
}
