/* src/components/Servicios/ServicioItem.css */

/* Estilo del contenedor principal de cada servicio */
.servicio-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.servicio-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Encabezado del servicio */
.servicio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.servicio-header h4 {
  margin: 0;
  color: #333;
  font-size: 1.1em;
  font-weight: bold;
}

.servicio-header p {
  color: #333;
  font-size: 1em;
}

/* Detalles del servicio (visible al expandir) */
.detalles-servicio {
  margin-top: 10px;
  font-size: 0.9em;
  color: #555;
}

.detalles-servicio p {
  margin: 5px 0;
}

.detalles-servicio h5 {
  margin-top: 15px;
  font-size: 1em;
  color: #333;
}

/* Lista de gastos adicionales */
.gastos-lista {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.gastos-lista li {
  padding: 5px 0;
  color: #555;
}

/* Botones de acción en cada servicio */
.servicio-item-buttons {
  margin-top: 15px;
}

.servicio-item-buttons .btn {
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9em;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
  margin-right: 5px;
}

.btn-editar {
  background-color: #007bff;
  color: white;
}

.btn-editar:hover {
  background-color: #0056b3;
}

.btn-duplicar {
  background-color: #6c757d;
  color: white;
}

.btn-duplicar:hover {
  background-color: #5a6268;
}

.btn-eliminar {
  background-color: #d9534f;
  color: white;
}

.btn-eliminar:hover {
  background-color: #c9302c;
}
