/* src/components/CalendarioReservas/CalendarioReservas.css */

/* ================================
   CONTENEDOR PRINCIPAL
   ================================ */
   .calendario-reservas-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
  
    /* Configuración para permitir scroll global si es necesario */
    height: 100vh;
    overflow-y: auto;
  }
  
  /* ================================
     SECCIÓN DEL CALENDARIO
     ================================ */
  .calendario {
    flex: 1;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .react-calendar {
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  /* Estilo básico para días */
  .react-calendar__tile {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s ease;
  }
  
  /* Hover genérico */
  .react-calendar__tile:hover {
    transform: scale(1.05);
  }
  
  /* Días feriados */
  .react-calendar__tile--holiday {
    background-color: #e57373 !important; /* Rojo claro */
    color: white;
    font-weight: bold;
  }
  
  .react-calendar__tile--holiday:hover {
    background-color: #ef5350 !important; /* Rojo */
  }
  
  /* Días con reservas */
  .react-calendar__tile--reserved {
    background-color: #81c784 !important; /* Verde claro */
    color: white;
    font-weight: bold;
    border: 2px solid #4caf50;
  }
  
  .react-calendar__tile--reserved:hover {
    background-color: #66bb6a !important; /* Verde */
    border-color: #388e3c;
  }
  
  /* Día seleccionado */
  .react-calendar__tile--selected:not(.react-calendar__tile--holiday):not(.react-calendar__tile--reserved) {
    background-color: #64b5f6 !important; /* Azul claro */
    color: white;
    font-weight: bold;
    border: 2px solid #2196f3;
  }
  
  .react-calendar__tile--selected:hover {
    background-color: #42a5f5 !important; /* Azul */
    border-color: #1e88e5;
  }
  
  /* Día actual */
  .react-calendar__tile--today:not(.react-calendar__tile--holiday):not(.react-calendar__tile--reserved) {
    background-color: #ffffff !important; /* Fondo blanco */
    color: #fbc02d !important; /* Amarillo oscuro */
    font-weight: bold;
    border: 2px solid #fbc02d !important; /* Borde amarillo */
  }
  
  .react-calendar__tile--today:hover {
    background-color: #fff9c4 !important; /* Fondo amarillo claro */
  }
  
  /* Combinaciones de estados */
  
  /* Día feriado y seleccionado */
  .react-calendar__tile--holiday.react-calendar__tile--selected {
    background-color: #d32f2f !important; /* Rojo oscuro */
    border-color: #b71c1c;
  }
  
  /* Día con reserva y seleccionado */
  .react-calendar__tile--reserved.react-calendar__tile--selected {
    background-color: #388e3c !important; /* Verde oscuro */
    border-color: #2e7d32;
  }
  
  /* Día feriado y hoy */
  .react-calendar__tile--holiday.react-calendar__tile--today {
    background-color: #c62828 !important; /* Rojo más oscuro */
    border: 2px solid #b71c1c !important;
  }
  
  /* Día con reserva y hoy */
  .react-calendar__tile--reserved.react-calendar__tile--today {
    background-color: #2e7d32 !important; /* Verde más oscuro */
    border: 2px solid #1b5e20 !important;
  }
  
  /* Día feriado, con reserva y hoy */
  .react-calendar__tile--holiday.react-calendar__tile--reserved.react-calendar__tile--today {
    background-color: #8e24aa !important; /* Morado */
    border: 2px solid #6a1b9a !important;
  }
  
  /* ================================
     SECCIÓN DE RESERVAS
     ================================ */
  .reservas {
    flex: 1;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .reservas ul {
    list-style: none;
    padding: 0;
  }
  
  .reserva-item {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s;
    background-color: #f8f9fa;
  }
  
  .reserva-item:hover {
    transform: scale(1.02);
    background-color: #f1f3f4;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  
  .reserva-item.reserva-actual {
    border: 2px solid #64b5f6;
    background-color: #e3f2fd;
  }
  
  .reserva-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 10px;
  }
  
  .reserva-details {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    line-height: 1.6;
  }
  
  .reserva-details strong {
    color: #333;
    display: block;
    margin-bottom: 5px;
  }
  
  /* Acciones de reserva */
  .reserva-actions {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }
  
  /* ================================
     BOTONES & CLASES DE BOTONES
     ================================ */
  
  /* Clase base para todos los botones */
  .btn {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff; /* Texto en blanco */
    background-color: #2196f3; /* Azul por defecto */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Hover genérico */
  .btn:hover {
    transform: scale(1.05);
  }
  
  /* Botón de Editar */
  .btn-editar {
    background-color: #2196f3; /* Azul */
  }
  .btn-editar:hover {
    background-color: #1976d2;
  }
  
  /* Botón de Eliminar */
  .btn-eliminar {
    background-color: #f44336; /* Rojo */
    flex-shrink: 0;
  }
  .btn-eliminar:hover {
    background-color: #d32f2f;
  }
  
  /* Botón para confirmar */
  .btn-confirmar {
    background-color: #4caf50; /* Verde */
  }
  .btn-confirmar:hover {
    background-color: #43a047;
  }
  
  /* Botón para cancelar */
  .btn-cancelar {
    background-color: #f44336; /* Rojo */
  }
  .btn-cancelar:hover {
    background-color: #d32f2f;
  }
  
  /* Botón para cerrar turno */
  .btn-cerrar-turno {
    background-color: #f44336; /* Rojo */
  }
  .btn-cerrar-turno:hover {
    background-color: #d32f2f;
  }
  
  /* Botón para abrir turno */
  .btn-abrir-turno {
    background-color: #4caf50; /* Verde */
  }
  .btn-abrir-turno:hover {
    background-color: #43a047;
  }
  
  /* Botón para cambiar empleado */
  .btn-cambiar-empleado {
    background-color: #ff9800; /* Naranja */
  }
  .btn-cambiar-empleado:hover {
    background-color: #fb8c00;
  }
  
  /* Botón para generar reporte */
  .btn-reporte {
    background-color: #9c27b0; /* Morado */
  }
  .btn-reporte:hover {
    background-color: #8e24aa;
  }
  
  /* ================================
     FORMULARIO DE RESERVAS
     ================================ */
  .formulario {
    flex: 1;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .formulario label {
    display: block;
    margin-bottom: 8px;
    color: #555;
  }
  
  .formulario input[type='text'],
  .formulario input[type='time'],
  .formulario input[type='date'],
  .formulario select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .error-text {
    color: #f44336;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Botones dentro del formulario */
  .formulario .btn {
    width: 100%;
    margin-bottom: 10px;
  }
  
  /* Botón principal (Crear / Actualizar) */
  .btn-primary {
    background-color: #4caf50; /* Verde */
  }
  .btn-primary:hover {
    background-color: #43a047;
  }
  
  /* Botón secundario (Cancelar) */
  .btn-secondary {
    background-color: #f44336; /* Rojo */
  }
  .btn-secondary:hover {
    background-color: #d32f2f;
  }
  
  /* ================================
     GESTIÓN DE TURNOS
     ================================ */
  .gestion-turnos {
    flex: 1;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .turno-abierto,
  .turno-cerrado {
    margin-top: 10px;
  }
  
  .cambiar-empleado {
    margin-top: 15px;
  }
  
  /* ================================
     REPORTE DE SERVICIOS
     ================================ */
  .reporte-servicios {
    flex: 1;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .reporte-servicios .form-group {
    margin-bottom: 15px;
  }
  
  .resultado-reporte {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    /* Configuración para scroll interno si el contenido es muy largo */
    max-height: 60vh;
    overflow-y: auto;
  }
  
  .resultado-reporte table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .resultado-reporte table th,
  .resultado-reporte table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  /* ================================
     MODAL DE CONFIRMACIÓN
     ================================ */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .modal p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  }
  
  .modal .btn {
    margin: 5px;
    width: auto;
  }
  
  /* ================================
     RESPONSIVIDAD
     ================================ */
  @media (max-width: 768px) {
    .calendario-reservas-container {
      flex-direction: column;
    }
  
    .calendario,
    .reservas,
    .formulario,
    .gestion-turnos,
    .reporte-servicios {
      max-width: 100%;
    }
  
    .reserva-summary {
      flex-direction: column;
      gap: 5px;
    }
  
    .reserva-actions {
      justify-content: center;
    }
  }
  