/* Contenedor Principal */
.productos-clientes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Sección de Filtros */
.productos-clientes-filtros {
  flex: 0 0 250px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Filtros de Orden */
.productos-clientes-orden-precio {
  margin-bottom: 20px;
}

.productos-clientes-orden-precio label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  font-size: 1rem;
}

.productos-clientes-orden-precio select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Filtros de Categorías y Marcas */
.productos-clientes-filtro-categorias,
.productos-clientes-filtro-marcas {
  margin-bottom: 20px;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  max-height: 40px; /* Altura mínima cuando están minimizadas */
  opacity: 1;
}

.productos-clientes-filtro-categorias.minimizado,
.productos-clientes-filtro-marcas.minimizado {
  max-height: 27px; /* Asegura que se mantenga la altura mínima */
  opacity: 1;
}

.productos-clientes-filtro-categorias.expandido,
.productos-clientes-filtro-marcas.expandido {
  max-height: 500px; /* Ajusta según la cantidad máxima de elementos */
  opacity: 1;
}

.productos-clientes-filtro-categorias h3,
.productos-clientes-filtro-marcas h3 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.categoria-checkbox,
.marca-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #555;
}

.expandir-icon {
  font-size: 14px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.productos-clientes-filtro-categorias.expandido .expandir-icon,
.productos-clientes-filtro-marcas.expandido .expandir-icon {
  transform: rotate(180deg); /* Icono rotado para mostrar el desplegado */
}

/* Opciones Adicionales */
.productos-clientes-opciones-adicionales {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mostrar-todo-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: #555;
}

.reset-filtros-button {
  padding: 8px 12px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.reset-filtros-button:hover {
  background-color: #c82333;
}

/* Disclaimers */
.productos-clientes-disclaimers {
  margin-top: 20px;
}

.disclaimer {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-left: 4px solid #007b5e;
  font-size: 0.9rem;
  color: #777;
}

/* Grid de Productos */
.productos-clientes-grid {
  flex: 1;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: center;
  align-items: start;
}

/* Tarjeta de Producto */
.productos-clientes-producto-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  min-height: 350px;
  position: relative;
}

.productos-clientes-producto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.productos-clientes-producto-card img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
}

.productos-clientes-producto-nombre {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
  min-height: 48px;
}

.productos-clientes-producto-precio {
  font-size: 1rem;
  color: #007b5e;
  margin-bottom: 10px;
  min-height: 24px;
}

/* Descripción con expansión dinámica */
.productos-clientes-producto-descripcion {
  font-size: 0.9rem;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  transition: max-height 0.3s ease;
  max-height: 60px;
  flex-grow: 1;
}

.productos-clientes-producto-descripcion.expandido {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  max-height: 500px;
  overflow: visible;
}

/* Botón para Expandir Descripción */
.productos-clientes-expandir-descripcion {
  margin-top: 10px;
  background-color: #007b5e;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.productos-clientes-expandir-descripcion:hover {
  background-color: #005f3f;
}

/* Nota de Precio en Tarjetas */
.productos-clientes-precio-nota {
  font-size: 0.8rem;
  color: #777;
  margin-top: 10px;
  text-align: center;
}

/* Mensajes de Estado */
.productos-clientes-loading,
.productos-clientes-error,
.productos-clientes-no-results {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

/* Responsividad */
@media (max-width: 768px) {
  .productos-clientes-container {
    flex-direction: column;
    align-items: stretch;
  }

  .productos-clientes-filtros {
    width: 100%;
    margin-bottom: 20px;
  }

  .productos-clientes-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .productos-clientes-producto-card {
    min-height: 300px;
  }

  /* Mejoras para categorías y marcas en móviles */
  .productos-clientes-filtro-categorias,
  .productos-clientes-filtro-marcas {
    max-height: 80px; /* Aumenta un poco para ver más opciones por defecto en móvil */
  }

  .categoria-checkbox,
  .marca-checkbox {
    font-size: 0.8rem; /* Reduce el tamaño del texto para más compacto */
    margin-bottom: 3px; /* Reduce el espacio entre opciones */
  }

  .productos-clientes-filtro-categorias h3,
  .productos-clientes-filtro-marcas h3 {
    font-size: 0.9rem; /* Reduce el tamaño del título en móvil */
  }

  .expandir-icon {
    font-size: 12px; /* Ajusta el tamaño del ícono para móvil */
  }

  .productos-clientes-filtro-categorias.expandido,
  .productos-clientes-filtro-marcas.expandido {
    max-height: 600px; /* Más espacio para desplegar en móvil */
  }

  /* Ajustes específicos para la tarjeta de producto en móvil */
  .productos-clientes-producto-card img {
    height: 100px; /* Menor altura para imágenes en móviles */
  }

  .productos-clientes-producto-nombre {
    font-size: 0.9rem;
    min-height: 40px;
  }

  .productos-clientes-producto-precio {
    font-size: 0.8rem;
    min-height: 20px;
  }

  .productos-clientes-producto-descripcion {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-height: 40px;
  }

  /* Ajustes para Opciones Adicionales en móviles */
  .productos-clientes-opciones-adicionales {
    flex-direction: column;
    gap: 10px;
  }

  .mostrar-todo-checkbox {
    font-size: 0.8rem;
  }

  .reset-filtros-button {
    font-size: 0.8rem;
    padding: 6px 10px;
  }

  /* Ajustes para el Disclaimer en móviles */
  .productos-clientes-disclaimers {
    margin-top: 15px;
  }

  .disclaimer {
    font-size: 0.8rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  
  .productos-clientes-disclaimers {
    margin-top: 10px;
    padding: 5px;
  }

  /* Opciones Adicionales en pantallas muy pequeñas */
  .productos-clientes-opciones-adicionales {
    gap: 5px;
  }

  .reset-filtros-button {
    padding: 5px 8px;
  }

  .mostrar-todo-checkbox {
    font-size: 0.7rem;
  }

  .productos-clientes-orden-precio label {
    font-size: 0.9rem;
  }

  .productos-clientes-orden-precio select {
    font-size: 0.8rem;
  }
}

/* Styles for the "Mostrar Todo" Checkbox */
.mostrar-todo-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease;
}

.mostrar-todo-checkbox input[type="checkbox"]:checked {
  background-color: #007b5e;
  border-color: #007b5e;
}

.mostrar-todo-checkbox input[type="checkbox"]:checked::after {
  content: '\2714'; /* Unicode checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}